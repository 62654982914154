// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PxgpSOsPseDWr3cWeWAF{background-color:#d9d7d2;height:6px;width:100%;border-radius:.4735rem;overflow:hidden}.PxgpSOsPseDWr3cWeWAF .mgD1wTTIf8zDtv3PNeUv{background-color:#434b4f;border-radius:.4735rem;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBarRange": "PxgpSOsPseDWr3cWeWAF",
	"progressBar": "mgD1wTTIf8zDtv3PNeUv"
};
export default ___CSS_LOADER_EXPORT___;
