import styles from './ProgressBar.module.scss';
export function ProgressBar({ min, max, value }: { min: number, max: number, value: number }) {

    if (min>max || value<min || value>max)
        return <div></div>

    return <div className={styles.progressBarRange}>
        <div className={styles.progressBar} style={{ 'width': `${Math.floor(((value - min) / (max - min)) * 100) }%`} }>
        </div>
    </div>
}